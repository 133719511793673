body {
    background-image: url('blobs_yellow_red.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.app {
    display: flex;
    justify-content: space-between;
}

.playImage {
    width: 20vmin;
    height: auto;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50wmin;
    margin-bottom: 50wmin;
    transition-duration: 0.3s;
    
}

.playImage:hover {
    width: 19vmin;
    height: auto;
}

.distortLogo {
    width: 200px;
    height: auto;
}

.radioContainer {
    display: flex;
    justify-content: centre;
    flex-direction: row;
    justify-content: space-between;
}
.dropdown {
    padding: 20px;
    cursor: pointer;
}

.dropdownContent {
    display: flex;
    flex-direction: column;
    font-family: 'Kanit', sans-serif;
    padding: 10px;
    margin-bottom: 20px;
}

.logoContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    right: 20px;
}

.radioPage {
    width: 50%;
    position: absolute;
    top: 30%;

}

.dropdownElement {
    margin-bottom: 5px;
    color: black;
    /* color: #ff3300; */
    text-decoration: none;
}
.aboutText {
    text-align: center;
    font-family: 'Kanit', sans-serif;
    font-weight: 200;
    width: 50%;
    position: absolute;
    top: 30%;
    left: 25%;
}
.aboutText::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.6);
    background-size: cover; /* Cover the entire area of the element */
    filter: blur(10px); /* Apply your desired level of blur */
    z-index: -1; /* Position behind the content */
}
.overlay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.0)
}
.socialWrapper {
    position: absolute;
    height: auto;
    right: 20vmin;
    display: flex;

}
.socialButton {
    width: 50px;
}

.azuraController {
    position: absolute;
    width: 400px;
    height:300px;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-30%, -50%);
    display: flex;
    justify-content: center;
}

.azuraControllerRadio {
    position: absolute;
}

.timetableWrapper {
    display: flex;
    justify-content: center;
}



.radioWrapper {
    display: flex;
    justify-content: center;
}

.aboutWrapper {
    top: 0px;
    display: flex;
    justify-content: center;
}

.playerButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.songInfoText {
    font-family: 'Kanit', sans-serif;
    text-align: center;
}

.cosiesButton {
    background-color: orange;
    border-radius: 5px;
    padding: 5px;
    font-family: 'Kanit', sans-serif;
}
button:hover {
    cursor: pointer;
}
.cosiesContainer {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chatRoomPlayButton {
    width: 50px;
    height: auto;
    top: 80%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.chatroom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 70vh;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fatbelly-logo {
    /* position: absolute; */
    width: 200px;
    height: auto;
    /* top: 150%;
    left: 50%;
    transform: translate(-50%, -50%); */
    text-align: center;
}